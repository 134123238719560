import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import styled from "styled-components"
import Layout from "@utils/layout"
import { graphql } from "gatsby"
import { Footer } from "@lib/simba/_statics"
import Seo from "@components/Seo"
import { ARTICLE } from "@utils/get-schemas"
import getSeo from "@utils/get-seo"
import Chooser from "@lib/simba/content-blocs"
import Divbox from "@lib/simba/divbox"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import { Link } from "gatsby"

const Whole = styled.div`
  position: relative;
  z-index: 8999;
  p {
    margin: 0;
  }
`

const Head = styled.div`
  position: relative;
  z-index: 1;
  padding: 0;
  background: white;
  align-items: center;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    width: 100vw;
  }
`
const Resume = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 95%;
  padding: ${({ theme }) => theme.paddingBox};
  background: ${({ theme }) => theme.header.background};
  color: ${({ theme }) => theme.header.colorText};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  & p:last-child {
    margin-bottom: 0;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    padding: calc(${({ theme }) => theme.paddingBox}*2);
    width: 50%;
  }
`
const Title = styled.h1`
  text-transform: uppercase;
  text-align: center;
`

const ImgContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 75vh;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
  }
`
const StyledBackgroundImg = styled(BackgroundImage)`
  display: block;
  width: 100%;
  height: 100%;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
  }
`
const LayoutArticle = styled.div`
  padding: ${({ theme }) => theme.paddingBox};
  position: relative;
  width: 95vw;
  margin: auto;
  background: white;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    top: -3rem;
    width: 75vw;
  }
`
const LogoContainer = styled.div`
  position: absolute;
  top: -40px;
  left: 5vw;
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    background: ${({ theme }) => theme.backgroundLogo};
    border-radius: 50%;
    top: 1vw;
    left: 5vw;
  }
`
const Logo = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  z-index: 5000;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
  }
`
const Article = ({ data }) => (
  <Layout>
    <Seo seo={getSeo(data.article, ARTICLE)} />
    <Head>
      <Link to="/">
        <LogoContainer>
          <Logo fixed={data.sanityIdentity.logo.asset.fixed} alt="" />
        </LogoContainer>
      </Link>
      <ImgContainer>
        <StyledBackgroundImg
          fluid={data.article.cover.asset.fluid}
          imgStyle={{
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />
      </ImgContainer>
      <Resume>
        <Title>{data.article.title}</Title>
        <BlockContent blocks={data.article._rawContent}></BlockContent>
      </Resume>
    </Head>
    <Whole>
      {data.contents.nodes.length !== 0 ? (
        <LayoutArticle>
          {data.contents.nodes.map((content, index) => {
            return (
              <Divbox>
                <Chooser
                  typebloc={content.typebloc}
                  rawContent={content._rawContent}
                  imgs={content.images}
                  url={content._rawLinks}
                  isEven={index % 2 === 0}
                />
              </Divbox>
            )
          })}
        </LayoutArticle>
      ) : (
        <></>
      )}
    </Whole>
    <Footer />
  </Layout>
)

export default Article

export const query = graphql`
  query Content($id: String) {
    article: sanityContenu(id: { eq: $id }) {
      title
      resume
      _rawContent(resolveReferences: { maxDepth: 5 })
      categories {
        title
      }
      seo {
        title
        keywords
        description
      }
      cover {
        asset {
          url
          metadata {
            dimensions {
              height
              width
            }
          }
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    sanityIdentity {
      logo {
        asset {
          fixed(width: 100) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    contents: allSanityContentbloc(
      sort: { fields: order }
      filter: { linkedcontent: { id: { eq: $id } } }
    ) {
      nodes {
        typebloc
        _rawLinks
        _rawContent(resolveReferences: { maxDepth: 5 })
        images {
          asset {
            url
            fluid(maxWidth: 1280) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    sidebar: allSanityContenu(
      filter: {
        categories: { elemMatch: { slug: { current: { eq: "nos-metiers" } } } }
        id: { ne: $id }
      }
    ) {
      nodes {
        title
        slug {
          current
        }
        categories {
          title
        }
        cover {
          asset {
            fixed(width: 120) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }
    }
  }
`
